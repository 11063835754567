import { IFetchResponse } from '@/types/IFetchResponse';
import { IAutocompleteResult } from '@/types/IAutocompleteResult';
import { callSearchApi } from '@/api/apiHelper';


export const getWords = async (keyword: string): Promise<IFetchResponse<IAutocompleteResult[]>> => {
  return await callSearchApi("get", "/autocomplete", {
    params: { keyword },
  });
};

export const getSymptoms = async (keyword: string): Promise<IFetchResponse<IAutocompleteResult[]>> => {
  return await callSearchApi("get", "/autocomplete-symptom", {
    params: { keyword },
  });
};
