import { IFetchResponse } from "@/types/IFetchResponse";
import { callProfileApi } from "@/api/apiHelper";
import { IPaginationRequest } from "@/types/IPaginationRequest";
import {
  IOrganization,
  IOrganizationDataSource,
  IOrganizationFeature,
  IOrganizationToAdd
} from "@/types/IOrganization";

export const getOrgs = async (
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IOrganization[] }>> => {
  const params = {
    k: keyword,
    p: pageNumber,
    pp: pageSize,
  }

  return await callProfileApi("get", "/s-admin/organizations", { params });
};

export const getOrg = async (id: string): Promise<IFetchResponse<IOrganization>> => {
  return await callProfileApi("get", `/s-admin/organizations/${id}`);
};

export const getOrgFeatures = async (id: string): Promise<IFetchResponse<IOrganizationFeature[]>> => {
  return await callProfileApi("get", `/s-admin/organizations/${id}/features`);
};

export const updateOrgFeature = async (id: string, featureId: string, feature: {
  value: number;
  isActive: boolean;
}) => {
  return await callProfileApi("put", `/s-admin/organizations/${id}/features/${featureId}`, feature);
}

export const getOrgDataSources = async (id: string): Promise<IFetchResponse<IOrganizationDataSource[]>> => {
  return await callProfileApi("get", `/s-admin/organizations/${id}/sources`);
};

export const updateOrgDataSource = async (id: string, dataSourceId: string) => {
  return await callProfileApi("put", `/s-admin/organizations/${id}/sources/${dataSourceId}`);
}

export const addOrg = async (org: IOrganizationToAdd): Promise<IFetchResponse<{ totalAmount: number; items: IOrganization[] }>> => {
  return await callProfileApi("post", "/s-admin/create-organization", org);
}

export const deleteOrg = async (id: string) => {
  return await callProfileApi("delete", `/s-admin/organizations/${id}`);
}

export const generateOrgKey = async (id: string) => {
  return await callProfileApi("put", `/s-admin/organizations/${id}/renew`);
}

export const getToken = async (): Promise<IFetchResponse<{ token: string }>> => {
  return await callProfileApi("get", `/s-admin/token`);
}
