import * as glossary from './glossary';
import * as autocomplete from './autocomplete';
import * as users from './users';
import * as doctors from './doctors';
import * as insurances from './insurances';
import * as organizations from './organizations';
import * as resellers from './resellers';
import * as affiliates from './affiliates';

export default {
  glossary,
  autocomplete,
  users,
  doctors,
  insurances,
  organizations,
  resellers,
  affiliates
};
