import { IFetchResponse } from "@/types/IFetchResponse";
import { callProfileApi } from "@/api/apiHelper";
import { IPaginationRequest } from "@/types/IPaginationRequest";
import { IReseller } from "@/types/IReseller";

export const getResellers = async (
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IReseller[] }>> => {
  const params = {
    k: keyword,
    p: pageNumber,
    pp: pageSize,
  }

  return await callProfileApi("get", "/s-admin/resellers", params);
};
