import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types/IFetchResponse";
import { IOrganization } from "@/types/IOrganization";

const glossaryUrl = "/glossary";

export const getNppesOrgs = async (keyword: string): Promise<IFetchResponse<IOrganization[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/nppes-organizations`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};
