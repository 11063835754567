<template>
  <header class="header">
    <div class="container">
      <div class="logo-wrapper">
        <img
          src="/img/logo-img.png"
          width="24"
          height="31"
          class="logo"
          alt="logo"
        />
        <h1 class="header-title">PG Dashboard</h1>
      </div>

      <TheHeaderUserMenu />
    </div>
  </header>
</template>

<script lang="ts">
import Vue from "vue";
import TheHeaderUserMenu from "@/components/TheHeaderUserMenu.vue";
export default Vue.extend({
  name: "TheHeader",
  components: {
    TheHeaderUserMenu
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.header {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: var(--color-white);

  .container {
    @include container-large;

    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .logo-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;

    .header-title {
      font-size: var(--font-big);
    }
  }
}
</style>
