import { Commit } from "vuex";

export interface IBreadcrumb {
  name: string;
  to?: { name: string };
  exact?: boolean;
}

export interface IBreadcrumbsState {
  breadcrumbs: IBreadcrumb[];
}

export default {
  namespaced: true,
  state: (): IBreadcrumbsState => ({
    breadcrumbs: [],
  }),
  getters: {
    getBreadcrumbs: (state: IBreadcrumbsState) => state.breadcrumbs,
  },
  mutations: {
    ADD_BREADCRUMB: (state: IBreadcrumbsState, breadcrumb: IBreadcrumb) => {
      state.breadcrumbs.push(breadcrumb);
    },
    SET_BREADCRUMBS: (state: IBreadcrumbsState, breadcrumbs: IBreadcrumb[]) => {
      state.breadcrumbs = breadcrumbs;
    }
  },
  actions: {
    addBreadcrumb: ({ commit }: { commit: Commit }, breadcrumb: IBreadcrumb) => {
      commit("ADD_BREADCRUMB", breadcrumb);
    },
    setBreadcrumbs: ({ commit }: { commit: Commit }, breadcrumbs: IBreadcrumb[]) => {
      commit("SET_BREADCRUMBS", breadcrumbs);
    },
  },
};
