<template>
  <div v-if="getUser" class="header-user-menu">
    <v-menu v-model="isMenuOpen" transition="slide-y-transition" bottom :close-on-content-click="false" left content-class="menu">
      <template #activator="{}">
        <button class="dropdown-btn" @click="isMenuOpen = true">
          <span class="user-name">
            {{ `${getUser.firstName} ${getUser.lastName}` }}
          </span>
          <v-icon color="var(--color-grey-primary)">{{ mdiChevronDown }}</v-icon>
        </button>
      </template>
      <div class="menu-header">
        <span class="user-name">{{ `${getUser.firstName} ${getUser.lastName}` }}</span>
      </div>
      <div class="menu-content">
        <button class="menu-item" @click="logOut">
          <v-icon size="20" color="var(--color-grey-primary)">{{ mdiLocationExit }}</v-icon>
          <span class="item-title">Log out</span>
        </button>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { getAuth, signOut } from "firebase/auth";
import { mapGetters, mapActions } from "vuex";
import { mdiChevronDown, mdiLocationExit } from "@mdi/js";

export default Vue.extend({
  name: "TheHeaderUserMenu",
  data() {
    return {
      mdiChevronDown,
      mdiLocationExit,
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    ...mapActions(["setUser", "setCurrentView"]),
    logOut() {
      signOut(getAuth());
      this.isMenuOpen = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.header-user-menu {
  display: flex;
  align-items: center;

  .user-name {
    margin-right: 8px;
    margin-left: 16px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-medium);
  }
}

.v-menu__content::v-deep.menu {
  display: flex;
  flex-direction: column;
  width: 220px;
  border-radius: 16px;

  .menu-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: var(--border-panel-purple);

    .user-name {
      max-width: 180px;
      font-weight: var(--font-weight-bolder);
      font-size: var(--font-medium);
      line-height: 20px;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
    }
  }

  .menu-content {
    display: flex;
    flex-direction: column;

    .menu-item {
      display: grid;
      grid-template-columns: 20px 1fr;
      gap: 10px;
      justify-items: start;
      padding: 16px;
      color: var(--color-grey-primary);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-small);
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: var(--color-purple-primary-light);
      }
    }
  }
}
</style>
