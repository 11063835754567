import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=17d2183e&scoped=true"
import script from "./TheHeader.vue?vue&type=script&lang=ts"
export * from "./TheHeader.vue?vue&type=script&lang=ts"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=17d2183e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17d2183e",
  null
  
)

export default component.exports