import Vue from "vue";
import Vuex from "vuex";
import breadcrumbs from "@/store/modules/breadcrumbs";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    breadcrumbs,
  },
  state: {
    user: null,
  },
  getters: {
    getUser: (state) => state.user,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    setUser: ({ commit }, user) => {
      commit("SET_USER", user);
    },
  },
});

