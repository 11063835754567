<template>
  <v-app v-if="isAppReady" id="app">
    <TheHeader :class="{ 'auth-header': authRoutes.includes($route.name) }" />

    <v-main class="main">
      <div class="wrapper">
        <router-view />
      </div>
    </v-main>

    <TheFooter  :class="{ 'auth-footer': authRoutes.includes($route.name) }"  />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import api from "@/api";

import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "App",
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      userFB: null as User | null,
      isAppReady: false,
      authRoutes: ["SignIn"],
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    isNeedRedirectToHomePage(): boolean {
      if (!this.$route.name) return false;
      return this.authRoutes.includes(this.$route.name);
    },
  },
  mounted() {
    onAuthStateChanged(getAuth(), async (userFB) => {
      this.userFB = userFB;

      if (!this.userFB && this.$route.name !== "SignIn") {
        this.isAppReady = true;
        this.setUser(null);
        this.$router.push({ name: "SignIn" });
        return;
      }

      if (!userFB) {
        this.isAppReady = true;
        return;
      }

      const { data: user } = await api.users.get();

      if (user) {
        this.setUser(user);
      }

      if (this.isNeedRedirectToHomePage) {
        this.isAppReady = true;
        this.$router.push({ name: "Home" });
        return;
      }

      this.isAppReady = true;
    });
  },
  methods: {
    ...mapActions(["setUser"]),
  },
});
</script>

<style lang="scss">
@import "@/assets/style/global";
@import "@/assets/style/mixins";

#app {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .main {
    background-color: var(--color-purple-light);
  }

  .wrapper {
    @include container-large;

    height: 100%;
  }

  .auth-header,
  .auth-footer {
    background-color: var(--color-purple-light);
  }
}
</style>
