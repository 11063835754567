import { IFetchResponse } from "@/types/IFetchResponse";
import { callProfileApi } from "@/api/apiHelper";
import { IPaginationRequest } from "@/types/IPaginationRequest";
import {
  IAffiliate,
  IAffiliateDataSource,
  IAffiliateFeature,
  IAffiliateToAdd
} from "@/types/IAffiliate";

export const getAffiliates = async (
  { pageNumber, pageSize, keyword }: IPaginationRequest
): Promise<IFetchResponse<{ totalAmount: number; items: IAffiliate[] }>> => {
  const params = {
    k: keyword,
    p: pageNumber,
    pp: pageSize,
  }

  return await callProfileApi("get", "/s-admin/affiliates", { params });
};

export const getAffiliate = async (id: string): Promise<IFetchResponse<IAffiliate>> => {
  return await callProfileApi("get", `/s-admin/affiliates/${id}`);
};

export const updateAffiliate = async (id: string, affiliate: IAffiliate) => {
  return await callProfileApi("put", `/s-admin/affiliates/${id}`, affiliate);
}

export const deleteAffiliate = async (id: string) => {
  return await callProfileApi("delete", `/s-admin/affiliates/${id}`);
}

export const getAffiliateDataSources = async (id: string): Promise<IFetchResponse<IAffiliateDataSource[]>> => {
  return await callProfileApi("get", `/s-admin/affiliates/${id}/sources`);
}

export const updateAffiliateDataSource = async (id: string, dataSourceId: string) => {
  return await callProfileApi("put", `/s-admin/affiliates/${id}/sources/${dataSourceId}`);
}

export const getAffiliateFeatures = async (id: string): Promise<IFetchResponse<IAffiliateFeature[]>> => {
  return await callProfileApi("get", `/s-admin/affiliates/${id}/features`);
};

export const updateAffiliateFeature = async (id: string, featureId: string, feature: {
  value: number;
  isActive: boolean;
}) => {
  return await callProfileApi("put", `/s-admin/affiliates/${id}/features/${featureId}`, feature);
}

export const addAffiliate = async (affiliate: IAffiliateToAdd): Promise<IFetchResponse<{ totalAmount: number; items: IAffiliate[] }>> => {
  return await callProfileApi("post", "/s-admin/affiliates", affiliate);
}

export const generateAffiliateKey = async (id: string) => {
  return await callProfileApi("put", `/s-admin/affiliates/${id}/renew`);
}
