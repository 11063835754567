import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '../views/SignInPage.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomePage.vue'),
    meta: {
      requiresAuth: true,
      breadCrumb: [
        {
          name: "PG Dashboard",
        },
      ],
    },
  },
  {
    path: '/debug-autocomplete',
    name: 'DebugAutocomplete',
    component: () => import(/* webpackChunkName: "DebugAutocomplete" */ '../views/DebugAutocomplete.vue'),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "DebugAutocomplete",
            name: "Debug Autocomplete",
          },
        ];
      },
    },
  },
  {
    path: '/debug-provider-match',
    name: 'DebugProviderMatch',
    component: () => import(/* webpackChunkName: "DebugProviderMatch" */ '../views/DebugProviderMatch.vue'),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "DebugProviderMatch",
            name: "Debug Provider Match",
          },
        ];
      },
    },
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import(/* webpackChunkName: "Organizations" */ '../views/OrganizationsPage.vue'),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "Organizations",
            name: "Organizations",
          },
        ];
      },
    },
  },
  {
    path: '/organizations/:id',
    name: 'Organization',
    component: () => import(/* webpackChunkName: "Organization" */ '../views/OrganizationPage.vue'),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            name: "Organizations",
            to: { name: "Organizations" },
            exact: true,
          },
        ];
      },
    },
  },
  {
    path: '/resellers',
    name: 'Resellers',
    component: () => import(/* webpackChunkName: "Resellers" */ '../views/ResellersPage.vue'),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "Resellers",
            name: "Resellers",
          },
        ];
      },
    },
  },
  {
    path: '/affiliates',
    name: 'Affiliates',
    component: () => import(/* webpackChunkName: "Affiliates" */ '../views/AffiliatesPage.vue'),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "Affiliates",
            name: "Affiliates",
          },
        ];
      },
    },
  },
  {
    path: '/affiliates/:id',
    name: 'Affiliate',
    component: () => import(/* webpackChunkName: "Affiliate" */ '../views/AffiliatePage.vue'),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            name: "Affiliates",
            to: { name: "Affiliates" },
            exact: true,
          },
        ];
      },
    },
  },
  {
    path: '*',
    redirect: { name: 'Home' },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
